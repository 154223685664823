  .header {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #ffffff90;
    z-index: 100;

    .contain {
      width: 90%;
      max-width: 1200px;
      height: 100%;
      display: flex;
      margin: auto 0;
      padding: 0 25px;
    }

    .logo {
      max-width: 258px;
      max-height: 47px;
      margin: auto auto auto 0;
      border-radius: 10px;
      z-index: 100;
    }

    .tel-flag {
      display: flex;
      flex-direction: column;
      max-width: 150px;
    }

    .tel {
      justify-content: center;
      font-family: "FiraCode", sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      margin: 10px auto;
    }

    .flags{
      display: flex;
      flex-direction: row;
      width: 80%;
      margin: auto auto auto 14px;
      justify-content: space-around;
    }
   /* .active {
        background-color: #fff;
        width: 30px;
        border-radius: 15px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
      }*/




      a {
        margin: auto 5px;
        font-size: 18px;
        text-decoration: none;
        color: black;
        &:hover {
          color: grey;
        }
      }
    }

